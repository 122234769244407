import React, { useState, useEffect } from "react";
import {
  Container,
  Alert,
  Card,
  Spinner,
  Form,
  FormControl,
  Button,
  ListGroup,
  Tab,
  Tabs,
  Badge,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./AllReviewers.css";

const AllReviewers = () => {
  const [verifiedReviewers, setVerifiedReviewers] = useState(null);
  const [pendingRequests, setPendingRequests] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const fetchVerifiedReviewers = async () => {
      try {
        const response = await axios.get(
          "/api/admin/dashboard/verifiedreviewers"
        );
        setVerifiedReviewers(response.data);
      } catch (error) {
        console.error("Error fetching verified reviewers:", error);
      }
    };

    const pendingRequestReviewers = async () => {
      try {
        const response = await axios.get(
          "/api/admin/dashboard/pendingrequests"
        );
        setPendingRequests(response.data);
      } catch (error) {
        console.error("Error when fetching pending request reviewers");
      }
    };
    fetchVerifiedReviewers();
    pendingRequestReviewers();
  }, []);

  const approveRequest = async (id) => {
    try {
      const response = await axios.patch(
        `/api/admin/dashboard/approvereviewer/${id}`
      );
      console.log(response.data);
      setAlert({
        type: "success",
        message: "Reviewer request approved successfully",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to approve reviewer request",
      });
      console.error("Error when approving reviewer!!");
    }
  };

  const removeFromReviewer = async (id) => {
    try {
      const response = await axios.patch(
        `/api/admin/dashboard/removefromreviewer/${id}`
      );
      console.log(response.data);
      setAlert({ type: "success", message: "Reviewer removed successfully" });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setAlert({
        type: "error",
        message: "Failed to remove reviewer from reviewer role",
      });
      console.error("Error when removing from reviewer role!!");
    }
  };

  let i = 0,
    j = 0;

  return (
    <div>
      <Helmet>
        <title>All Reviewers - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="col-lg-7 allreviewers">
        <h3>All Reviewers Dashboard</h3>
        {alert && (
          <Alert
            variant={alert.type}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}

        <Tabs
          defaultActiveKey="verifiedreviewers"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="verifiedreviewers"
            title={
              <React.Fragment>
                Verified
                <Badge variant="light" className="mx-1">
                  {verifiedReviewers?.length}
                </Badge>
              </React.Fragment>
            }
            //   title="Verified Reviewers"
          >
            <h5 className="mt-4">
              <b>Verified:</b>
            </h5>
            {verifiedReviewers?.length === 0 ? (
              <div>No verified reviewers found</div>
            ) : (
              <>
                <ListGroup>
                  {verifiedReviewers?.map((reviewer) => (
                    <ListGroup.Item key={i}>
                      <Accordion>
                        <Accordion.Item eventKey={i}>
                          <Accordion.Header>
                            <b>
                              {++i}. {reviewer.fullName}
                            </b>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="col-auto">
                              <ul>
                                <li>Name: {reviewer.fullName}</li>
                                <li>Email: {reviewer.email}</li>
                                <li>UserName: {reviewer.userName}</li>
                                <li>CreatedAt: {reviewer.createdAt}</li>
                                <li>
                                  Reviewed Blogs:{" "}
                                  {reviewer.reviewedBlogs.length}
                                </li>
                              </ul>
                            </div>
                            <div className="col-auto">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => removeFromReviewer(reviewer._id)}
                              >
                                Remove From Reviewer
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="pendingrequests"
            title={
              <React.Fragment>
                Pending Requests
                <Badge variant="light" className="mx-1">
                  {pendingRequests?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Pending Requests:</b>
            </h5>
            {pendingRequests?.length === 0 ? (
              <div>No Pending request found</div>
            ) : (
              <>
                <ListGroup>
                  {pendingRequests?.map((reviewer) => (
                    <ListGroup.Item key={reviewer.email}>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <b>
                              {++j}. {reviewer.fullName}
                            </b>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="col-auto">
                              <ul>
                                <li>Name: {reviewer.fullName}</li>
                                <li>Email: {reviewer.email}</li>
                                <li>UserName: {reviewer.userName}</li>
                                <li>CreatedAt: {reviewer.createdAt}</li>
                                <li>
                                  Reviewed Blogs:{" "}
                                  {reviewer.reviewedBlogs.length}
                                </li>
                              </ul>
                            </div>
                            <div className="col-auto">
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => approveRequest(reviewer._id)}
                              >
                                Approve Request
                              </Button>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default AllReviewers;
