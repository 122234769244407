import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./ReviewerLoginScreen.css";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

function ReviewerLoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [role, setRole] = useState("Reviewer");
  const [loginHeading, setLoginHeading] = useState("Reviewer");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setLoginHeading(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here

    // Create the request body
    const requestBody = {
      email: email,
      password: password,
    };

    if (role === "Reviewer") {
      console.log(role);
      // Send the login request to the backend
      axios
        .post("/api/reviewer/login", requestBody)
        .then(async (response) => {
          // Handle the login response here
          console.log(response.data.token);
          console.log(response.data.reviewerDetails);

          // Retrieve the token from the response
          const { token } = response.data;

          // Store the token in localStorage
          localStorage.setItem("currenttoken", token);

          // Check if the login was successful
          if (response.status === 200) {
            setSuccess("Login successful");
            setError("");

            const headers = {
              Authorization: `Bearer ${token}`,
            };
            console.log(headers);

            if (response.data.reviewerDetails.isVerified) {
              await axios.get("/api/blogs", { headers });
              // Redirect to the homepage
              setTimeout(() => {
                navigate("/");
              }, 500);
            } else {
              console.log("Error");
            }
          } else {
            setSuccess("");
            setError("Login failed");
          }
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
          setSuccess("");
          setError(error.response.data.message);
        });
    } else {
      // Send the login request to the backend
      axios
        .post("/api/admin/login", requestBody)
        .then(async (response) => {
          // Handle the login response here
          console.log(response.data.token);
          console.log(response.data.adminDetails);

          // Retrieve the token from the response
          const { token } = response.data;

          // Store the token in localStorage
          localStorage.setItem("currenttoken", token);

          // Check if the login was successful
          if (response.status === 200) {
            setSuccess("Login successful");
            setError("");

            const headers = {
              Authorization: `Bearer ${token}`,
            };
            console.log(headers);

            if (response.data.adminDetails.isVerified) {
              await axios.get("/api/blogs", { headers });

              // Redirect to the homepage
              setTimeout(() => {
                navigate("/");
              }, 500);
            } else {
              console.log("Error");
            }
          } else {
            setSuccess("");
            setError("Login failed");
          }
        })
        .catch((error) => {
          // Handle any errors here
          console.error(error);
          setSuccess("");
          setError(error.response.data.message);
        });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Login - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <div className="login-page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 ol-md-6 col-sm-8">
              <div className="login-form">
                <h2 className="text-center mb-4">{loginHeading} Login</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <div className="password-input">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <i
                        className={`toggle-password fas ${
                          showPassword ? "fa-eye-slash" : "fa-eye"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></i>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="role">
                    {" "}
                    {/* Added form group for role */}
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      as="select"
                      value={role}
                      onChange={handleRoleChange}
                    >
                      <option value="Reviewer">Reviewer</option>
                      <option value="Admin">Admin</option>
                    </Form.Control>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    className="loginbutton"
                    block
                  >
                    Login
                  </Button>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={() => navigate("/forgotpassword")}
                    className="forgotpassbutton"
                    block
                  >
                    Forgot password?
                  </Button>
                  <div>
                    Don't have an account? <Link to="/signup">Sign up</Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewerLoginScreen;
