import React, { useState, useEffect } from "react";
import {
  Container,
  Alert,
  Card,
  Spinner,
  Form,
  FormControl,
  Button,
  ListGroup,
  Tab,
  Tabs,
  Badge,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./AllUsers.css";

const AllUsers = () => {
  const [allUsers, setAllUsers] = useState(null);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await axios.get("/api/admin/dashboard/allusers");
        setAllUsers(response.data);
      } catch (error) {
        console.error("Error fetching all users:", error);
      }
    };
    fetchAllUsers();
  }, []);

  const deleteUserAccount = async (id, email) => {
    try {
      const response = await axios.delete(
        `/api/admin/dashboard/deleteuser/${id}`,
        { email }
      );
      console.log(response.data);
      setAlert({
        type: "success",
        message: "User account deleted successfully",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      setAlert({ type: "error", message: "Failed to delete user account" });
      console.error("Failed to delete user account");
    }
  };

  let i = 0;

  return (
    <div>
      <Helmet>
        <title>All Users - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="col-lg-7 allusers">
        <h3>All Users Dashboard</h3>
        {alert && (
          <Alert
            variant={alert.type}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}
        <h5 className="mt-4">
          <b>All Users:</b>
        </h5>
        {allUsers?.length === 0 ? (
          <div>No verified reviewers found</div>
        ) : (
          <>
            <ListGroup>
              {allUsers?.map((user) => (
                <ListGroup.Item key={i}>
                  <Accordion>
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>
                        <b>
                          {++i}. {user.fullName}
                        </b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-auto">
                          <ul>
                            <li>Name: {user.fullName}</li>
                            <li>Email: {user.email}</li>
                            <li>UserName: {user.userName}</li>
                            <li>
                              isVerified:{" "}
                              {user.isVerified ? "Verified" : "Not Verified"}
                            </li>
                            <li>CreatedAt: {user.createdAt}</li>
                          </ul>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              deleteUserAccount(user._id, user.email)
                            }
                          >
                            Delete Account
                          </Button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Container>
    </div>
  );
};

export default AllUsers;
