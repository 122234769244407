import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import Select from "react-select";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Newsletter = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [subject, setSubject] = useState("Newsletter");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const resp = await axios.get("/api/admin/dashboard/allusers");
        console.log(resp);
        // setUsers(resp.data);

        setUsers([]);
        resp.data.map((user) => {
          const ob = {
            label: user.email,
            value: user.email,
          };
          setUsers((users) => [...users, ob]);
          // if(!users.includes(ob))
        });
      } catch (error) {
        console.log("Error fetching all users");
      }
    };

    fetchAllUsers();
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await axios.post("/api/admin/newsletter/send", {
        message,
        selectedUsers,
        subject
      });
      console.log("Sent emails to users");
      toast.success("Newsletter sent successfully!!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      toast.error("Error sending newsletter", error);
      console.log("Error Sending emails to users");
    }
  };

  return (
    <div>
      <section className="newpage-section">
        <ToastContainer />
        <Container>
          <h3 className="page-title">Create newsletter</h3>
          <div className="heading-underline"></div>

          <Row className="justify-content-center">
            <Col md={6}>
              <h6>Email Subject line:</h6>
              <Form.Group controlId="subject" className="">
                <Form.Control
                  type="text"
                  value={subject}
                  placeholder="Enter subject"
                  onChange={(e)=>setSubject(e.target.value)}
                />
              </Form.Group>
              <h6>Email body message:</h6>
              <CKEditor
                editor={Editor}
                data="<p></p>"
                onChange={(event, editor) => {
                  setMessage(editor.getData());
                }}
              />
              <br />
              <h6>Select users: </h6>
              <Select
                className="react-select-dropdown"
                defaultValue={selectedUsers}
                onChange={setSelectedUsers}
                options={users}
                required
                isMulti
              />
              <br />
              <p>
                Selected users:{" "}
                {selectedUsers.map((user) => (
                  <small className="color-teal-green">
                    {user.value}
                    {", "}
                  </small>
                ))}
              </p>

              <Button className="bs-button" onClick={handleSubmit}>
                Send Newsletter
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Newsletter;
