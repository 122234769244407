import React, { useState, useEffect } from "react";
import { Container, Card, Spinner,Form,FormControl, Button, ListGroup } from "react-bootstrap";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import './AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const [selectedValue, setSelectedValue]= useState("");

  const handleSelectedChoice = (e) => {
    setSelectedValue(e.target.value);
    return navigate(e.target.value);
  };

  return (
    <div>
      <Helmet>
        <title>Admin Dashboard - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="col-lg-7 admindashboard">
      <h3>Admin dashboard</h3>

      <Form.Group controlId="blogCategory" className="newblogfields">
        <Form.Label>Please select from the dropdown:</Form.Label>
        <Form.Control
          as="select"
          value={selectedValue}
          onChange={handleSelectedChoice}
          placeholder="Select"
        >
          <option value="">Select</option>
          <option value="allusers">Users</option>
          <option value="allreviewers">Reviewers</option>
          {/* <option value="allblogs">Blogs</option> */}
        </Form.Control>
        {/* <Button
          variant="success"
          //   size="sm"
          onClick={()=>navigate("/")}
          className="my-2"
        >
          Go
        </Button> */}
      </Form.Group>
    </Container>
    </div>
  );
}

export default AdminDashboard;
