import React, { useEffect, useState } from "react";
import { Container, Card, Spinner, Button, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./PublishedBlogs.css";

function PublishedBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInReviewBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/published");
        setBlogs(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pending blogs:", error);
        // localStorage.removeItem("currenttoken");
        // navigate("/login");
      }
    };

    fetchInReviewBlogs();
  }, []);

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (blogs.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>No pending blogs found.</div>
      </Container>
    );
  }

  const getOverallRating = (reviewedby) => {
    let sum = 0;
    for (let index = 0; index < reviewedby.length; index++) {
      sum += reviewedby[index]["Rating"];
    }
    return sum / reviewedby.length;
  };

  return (
    <div>
      <Helmet>
        <title>Published Blogs - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="col-lg-7 publishedblogs">
        <h2 className="heading">Published Blogs</h2>
        {blogs?.length === 0 ? (
          <div>No blogs found</div>
        ) : (
          <>
            <ListGroup className="m-3">
              {blogs?.map((blog) => (
                <ListGroup.Item key={blog.slug} className="border hoverblog">
                  <div className="row align-items-center">
                    <Link
                      to={`${process.env.REACT_APP_BLOGGERSPACE}/${blog.slug}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <b>{blog.title}</b>
                        <p>
                          {/* <i className="text-muted">Author: {blog.authorDetails}</i>
                        <br /> */}
                          <i>
                            Content size:{" "}
                            {(
                              new TextEncoder().encode(blog.content).length /
                              1024
                            ).toFixed(5)}{" "}
                            KB
                          </i>
                          <br />
                          <i className="text-muted">
                            Last updated: {blog.lastUpdatedAt?.slice(0, 19)}
                          </i>{" "}
                          <br />
                          <i className="text-muted">
                            Rating: {getOverallRating(blog.reviewedBy)}
                          </i>
                          <br />
                          <Link
                            to={`/admin/blog/editblog/${blog._id}`}
                            //   target="_blank"
                            //   rel="noopener noreferrer"
                          >
                            <Button variant="primary">Edit</Button>
                          </Link>
                        </p>
                      </div>
                    </Link>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Container>
    </div>
  );
}

export default PublishedBlogs;
