import React, { useState, useEffect } from "react";
import { Container, Card, ListGroup, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./ReviewedBlogs.css";

const ReviewedBlogs = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  let i = 0;

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(
          "/api/reviewer/userdetails"
        );
        setUserProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (!userProfile) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>User not found.</div>
      </Container>
    );
  }

  const { fullName, email, reviewedBlogs } = userProfile;

  return (
      <div>
        <Helmet>
          <title>Reviewed Blogs - BloggerSpace Reviewer Panel</title>
        </Helmet>
<Container className="user-profile-page col-lg-7">
      <h2 className="user-profile-heading">Reviewed Blogs</h2>
      <Card>
        <Card.Body>
          <Card.Title>{fullName}</Card.Title>
          <Card.Text>Email: {email}</Card.Text>
        </Card.Body>
      </Card>

      <h5 className="mt-4">
        <b>Reviewed blogs:</b>
      </h5>
      {reviewedBlogs.length === 0 ? (
        <div>No blogs found</div>
      ) : (
        <ListGroup>
          {reviewedBlogs.map((blog) => (
            <ListGroup.Item key={blog.BlogSlug}>
              {++i}. {blog.BlogTitle}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>

      </div>
    );
};

export default ReviewedBlogs;
