import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  ListGroup,
  Spinner,
  Form,
  Button,
  Alert,
  Modal,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

const AdminBlogs = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [reviewers, setReviewers] = useState([]);
  const [draftBlogs, setDraftBlogs] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState({}); // Maintain assigned user state for each blog
  let i = 0,
    j = 0,
    k = 0;

  const [publishedBlogs, setPublishedBlogs] = useState(null);
  const [inReviewBlogs, setInReviewBlogs] = useState(null);
  const [discardedBlogs, setDiscardedBlogs] = useState(null);
  const [awaitingAuthorBlogs, setAwaitingAuthorBlogs] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewBlog, setPreviewBlog] = useState(null);
  const [previewContentSize, setPreviewContentSize] = useState(0);

  //  Size of the content
  useEffect(() => {
    const findContentSize = (content) => {
      setPreviewContentSize(
        (new TextEncoder().encode(content).length / 1024).toFixed(4)
      );
    };
    findContentSize(previewBlog?.content);
  }, [previewBlog]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("/api/reviewer/userdetails");
        setUserProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    const fetchDraftBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/blogs/drafts");
        setDraftBlogs(response.data);
      } catch (error) {
        console.error("Error fetching pending blogs:", error);
      }
    };

    const fetchPublishedBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/blogs/published");
        setPublishedBlogs(response.data);
      } catch (error) {
        console.error("Error fetching under review blogs:", error);
      }
    };

    const fetchDiscardedBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/blogs/discarded");
        setDiscardedBlogs(response.data);
      } catch (error) {
        console.error("Error fetching discard queue blogs:", error);
      }
    };

    fetchUserProfile();
    fetchDraftBlogs();
    fetchPublishedBlogs();
    fetchDiscardedBlogs();
  }, []);

  const handleDiscardBlog = async (blogId, slug) => {
    const confirmDiscard = window.confirm(
      "Are you sure you want to discard this blog?\n" + "Title: " + slug
    );
    if (confirmDiscard) {
      try {
        // Handle the response
        const response = await axios.post(`/api/admin/blogs/adminblogdiscard/${blogId}`);
        setAlert({ type: "success", message: "blog discarded successfully" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        console.error("Error discarding blog:", error.response.data);
      }
    }
  };

  const handlePreviewBlogModal = async (id) => {
    try {
      const response = await axios.get(`/api/admin/blog/editblog/${id}`);
      setPreviewBlog(response.data);
      setShowConfirmModal(true); // Show the confirmation modal
    } catch (error) {
      console.error("Error Fetching preview blog:", error.response.data);
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }


  return (
    <div>
      <Helmet>
        <title>Blogs Published by Admin - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="reviewerbyadmin-page col-lg-7">
        <h2 className="reviewerbyadmin-heading">Admin Blogs</h2>
        {alert && (
          <Alert
            variant={alert.type}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}

        <Tabs
          defaultActiveKey="draft"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="draft"
            title={
              <React.Fragment>
                Draft
                <Badge variant="light" className="mx-1">
                  {draftBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Draft:</b>
            </h5>
            {draftBlogs?.length === 0 ? (
              <div>No draft blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {draftBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++i}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>

                        <div className="col-auto">
                        <Link
                        to={`/admin/editblog/${blog._id}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"
                      >
                        <Button className="bs-button">Edit</Button>
                      </Link>
                        </div>

                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="published"
            title={
              <React.Fragment>
                Published
                <Badge variant="light" className="mx-1">
                  {publishedBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Published by Admin:</b>
            </h5>
            {publishedBlogs?.length === 0 ? (
              <div>No Published blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {publishedBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++j}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>

                        <div className="col-auto">
                        <Link
                        to={`/admin/editblog/${blog._id}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"
                      >
                        <Button className="bs-button" size="sm">Edit</Button>
                      </Link>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="discarded"
            title={
              <React.Fragment>
                Discarded Blogs
                <Badge variant="light" className="mx-1">
                  {discardedBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            {/* In Review */}
            <h5 className="mt-4">
              <b>Discarded Blogs:</b>
            </h5>
            {inReviewBlogs?.length === 0 ? (
              <div>No discarded blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {discardedBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++k}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                        </div>
                        <div className="col-auto">
                        <Link
                        to={`/admin/editblog/${blog._id}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"
                      >
                        <Button className="bs-button" size="sm">Re-publish</Button>
                      </Link>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>
        </Tabs>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Title: {previewBlog?.title}
            <br />
            Slug: {previewBlog?.slug}
            <br />
            <br />
            Content: <br />
            <div dangerouslySetInnerHTML={{ __html: previewBlog?.content }} />
            <h6>Content size: {previewContentSize} KB</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default AdminBlogs;
