import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Spinner,
  Button,
  Form,
  ListGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./InReview.css";

function InReview() {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchInReviewBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/inreviewblogs");
        setBlogs(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching pending blogs:", error);
        // localStorage.removeItem("currenttoken");
        // navigate("/login");
      }
    };

    fetchInReviewBlogs();
  }, []);

  if (isLoading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  if (blogs.length === 0) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>No pending blogs found.</div>
      </Container>
    );
  }
  var i = 0;

  const getBlogReviewedBy = (reviewedby) => {
    let res = [];
    for (let index = 0; index < reviewedby.length; index++) {
      res.push(
        reviewedby[index]["ReviewedBy"]["Email"] +
          " (Rating:" +
          reviewedby[index]["Rating"] +
          ")"
      );
    }
    return res.join(", ");
  };

  const getOverallRating = (reviewedby) => {
    let sum = 0;
    for (let index = 0; index < reviewedby.length; index++) {
      sum += reviewedby[index]["Rating"];
    }
    return sum / reviewedby.length;
  };

  return (
    <div>
      <Helmet>
        <title>In Review Blogs - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="col-lg-7 inreview">
        <h2 className="heading">In Review Blogs</h2>

        {blogs?.length === 0 ? (
          <div>No Pending blogs found</div>
        ) : (
          <>
            <ListGroup>
              {blogs?.map((blog) => (
                <ListGroup.Item key={blog.slug}>
                  <div className="row align-items-center">
                    <div className="col">
                      <b>
                        {++i}. {blog.title}{" "}
                      </b>
                      <p>
                        <i>author: {blog.authorEmail}</i> <br />
                        {/* <i>Reviewed By: {blog.reviewedBy.join(", ")}</i> */}
                        <i>
                          Reviewed By: {getBlogReviewedBy(blog.reviewedBy)}
                        </i>{" "}
                        <br />
                        <i>Rating: {getOverallRating(blog.reviewedBy)}</i>
                      </p>
                    </div>

                    <div className="col-auto">
                      <Link
                        to={`/admin/blog/editblog/${blog._id}`}
                        //   target="_blank"
                        //   rel="noopener noreferrer"
                      >
                        <Button variant="primary">Review</Button>
                      </Link>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Container>
    </div>
  );
}

export default InReview;
