import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  ListGroup,
  Spinner,
  Form,
  Button,
  Alert,
  Modal,
  Tab,
  Tabs,
  Badge,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "./BlogAssignment.css";
import { Helmet } from "react-helmet";

const BlogAssignment = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [reviewers, setReviewers] = useState([]);
  const [pendingBlogs, setPendingBlogs] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState({}); // Maintain assigned user state for each blog
  let i = 0,
    j = 0,
    k = 0,
    l = 0,
    m = 0;

  const [underReviewBlogs, setUnderReviewBlogs] = useState(null);
  const [inReviewBlogs, setInReviewBlogs] = useState(null);
  const [discardQueueBlogs, setDiscardQueueBlogs] = useState(null);
  const [awaitingAuthorBlogs, setAwaitingAuthorBlogs] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [previewBlog, setPreviewBlog] = useState(null);
  const [previewContentSize, setPreviewContentSize] = useState(0);

  //  Size of the content
  useEffect(() => {
    const findContentSize = (content) => {
      setPreviewContentSize(
        (new TextEncoder().encode(content).length / 1024).toFixed(4)
      );
    };
    findContentSize(previewBlog?.content);
  }, [previewBlog]);

  const handleAssignUser = (e, blogId) => {
    const { value } = e.target;
    // console.log("Reviewer: "+ value);
    setAssignedUsers((prevAssignedUsers) => ({
      ...prevAssignedUsers,
      [blogId]: value,
    }));
  };

  const handleAssignBlog = async (blogId) => {
    try {
      const assignedUser = assignedUsers[blogId];
      // console.log("Assigned user: "+assignedUser)
      if (assignedUser) {
        const response = await axios.patch(`/api/admin/assign/blog/${blogId}`, {
          assignedUser,
        });
        // Handle the response
        setAlert({
          type: "success",
          message: "Blog assigned to: " + assignedUser,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error assigning blog:", error.response.data);
    }
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("/api/reviewer/userdetails");
        setUserProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    const fetchReviewers = async () => {
      try {
        const response = await axios.get("/api/admin/allreviewers");
        setReviewers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchPendingBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/pendingblogs");
        setPendingBlogs(response.data);
      } catch (error) {
        console.error("Error fetching pending blogs:", error);
      }
    };

    const fetchUnderReviewBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/underreviewblogs");
        setUnderReviewBlogs(response.data);
      } catch (error) {
        console.error("Error fetching under review blogs:", error);
      }
    };

    const fetchInReviewBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/inreviewblogs");
        setInReviewBlogs(response.data);
      } catch (error) {
        console.error("Error fetching inreview blogs:", error);
      }
    };

    const fetchDiscardQueueBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/discardqueueblogs");
        setDiscardQueueBlogs(response.data);
      } catch (error) {
        console.error("Error fetching discard queue blogs:", error);
      }
    };
    const fetchAwaitingAuthorBlogs = async () => {
      try {
        const response = await axios.get("/api/admin/awaitingauthorblogs");
        setAwaitingAuthorBlogs(response.data);
      } catch (error) {
        console.error("Error fetching discard queue blogs:", error);
      }
    };

    fetchUserProfile();
    fetchReviewers();
    fetchPendingBlogs();
    fetchUnderReviewBlogs();
    fetchInReviewBlogs();
    fetchDiscardQueueBlogs();
    fetchAwaitingAuthorBlogs();
  }, []);

  const handleDiscardBlog = async (blogId, authorEmail, slug) => {
    const confirmDiscard = window.confirm(
      "Are you sure you want to discard this blog?\n" + "Title: " + slug
    );
    if (confirmDiscard) {
      try {
        const response = await axios.post(`/api/admin/discard/blog/${blogId}`, {
          authorEmail,
          slug,
        });
        // Handle the response
        setAlert({ type: "success", message: "blog discarded successfully" });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        console.error("Error discarding blog:", error.response.data);
      }
    }
  };

  const handlePreviewBlogModal = async (id) => {
    try {
      const response = await axios.get(`/api/admin/blog/editblog/${id}`);
      setPreviewBlog(response.data);
      setShowConfirmModal(true); // Show the confirmation modal
    } catch (error) {
      console.error("Error Fetching preview blog:", error.response.data);
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </Container>
    );
  }

  //   if (!pendingBlogs) {
  //     return (
  //       <Container className="d-flex justify-content-center align-items-center vh-100">
  //         <div>No pending blog found.</div>
  //       </Container>
  //     );
  //   }

  const { fullName, email, role } = userProfile;

  return (
    <div>
      <Helmet>
        <title>Blog Assignment - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="reviewerbyadmin-page col-lg-7">
        <h2 className="reviewerbyadmin-heading">Assign Blogs</h2>
        {alert && (
          <Alert
            variant={alert.type}
            onClose={() => setAlert(null)}
            dismissible
          >
            {alert.message}
          </Alert>
        )}

        {/* <Card>
        <Card.Body>
          <Card.Title>
            {fullName} ({role})
          </Card.Title>
          <Card.Text>Email: {email}</Card.Text>
        </Card.Body>
      </Card> */}

        <Tabs
          defaultActiveKey="pending"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            eventKey="pending"
            title={
              <React.Fragment>
                Pending
                <Badge variant="light" className="mx-1">
                  {pendingBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Pending for Assignment:</b>
            </h5>
            {pendingBlogs?.length === 0 ? (
              <div>No Pending blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {pendingBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++i}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                          <p>
                            <i>author: {blog.authorDetails.email}</i> <br />
                            <i>Reviewer: {blog.currentReviewer}</i>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Form.Group
                            controlId={`assignUserSelect-${blog._id}`}
                          >
                            <Form.Control
                              as="select"
                              value={assignedUsers[blog._id] || ""}
                              onChange={(e) => handleAssignUser(e, blog._id)}
                            >
                              <option value="">Select Reviewer</option>
                              {reviewers.map((reviewer) => (
                                <option
                                  key={reviewer._id}
                                  value={reviewer.email}
                                  disabled={
                                    blog.authorDetails.email === reviewer.email
                                  }
                                >
                                  {reviewer.email}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            size="sm"
                            className="m-2"
                            onClick={() => handleAssignBlog(blog._id)}
                          >
                            Assign User
                          </Button>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.authorDetails.email,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="UnderReview"
            title={
              <React.Fragment>
                Under Review
                <Badge variant="light" className="mx-1">
                  {underReviewBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            <h5 className="mt-4">
              <b>Under Review:</b>
            </h5>
            {underReviewBlogs?.length === 0 ? (
              <div>No Under Review blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {underReviewBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++j}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                          <p>
                            <i>author: {blog.authorDetails.email}</i> <br />
                            <i>Reviewer: {blog.currentReviewer}</i>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Form.Group
                            controlId={`assignUserSelect-${blog._id}`}
                          >
                            <Form.Control
                              as="select"
                              value={assignedUsers[blog._id] || ""}
                              onChange={(e) => handleAssignUser(e, blog._id)}
                            >
                              <option value="">Select Reviewer</option>
                              {reviewers.map((reviewer) => (
                                <option
                                  key={reviewer._id}
                                  value={reviewer.email}
                                  disabled={
                                    blog.authorDetails.email ===
                                      reviewer.email ||
                                    blog.currentReviewer === reviewer.email
                                  }
                                >
                                  {reviewer.email}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            size="sm"
                            className="m-2"
                            onClick={() => handleAssignBlog(blog._id)}
                          >
                            Update
                          </Button>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.authorDetails.email,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="inreview"
            title={
              <React.Fragment>
                In Review
                <Badge variant="light" className="mx-1">
                  {inReviewBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            {/* In Review */}
            <h5 className="mt-4">
              <b>In Review:</b>
            </h5>
            {inReviewBlogs?.length === 0 ? (
              <div>No InReview blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {inReviewBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++k}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                          <p>
                            <i>author: {blog.authorDetails.email}</i> <br />
                            <i>Reviewer: {blog.currentReviewer}</i>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Form.Group
                            controlId={`assignUserSelect-${blog._id}`}
                          >
                            <Form.Control
                              as="select"
                              value={assignedUsers[blog._id] || ""}
                              onChange={(e) => handleAssignUser(e, blog._id)}
                            >
                              <option value="">Select Reviewer</option>
                              {reviewers.map((reviewer) => (
                                <option
                                  key={reviewer._id}
                                  value={reviewer.email}
                                  disabled={
                                    blog.authorDetails.email ===
                                      reviewer.email ||
                                    blog.currentReviewer === reviewer.email
                                  }
                                >
                                  {reviewer.email}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            size="sm"
                            className="m-2"
                            onClick={() => handleAssignBlog(blog._id)}
                          >
                            Update
                          </Button>
                        </div>
                        <div className="col-auto">
                          <Link to={`/admin/blog/editblog/${blog._id}`}>
                            <Button variant="primary" size="sm" className="m-2">
                              Review
                            </Button>
                          </Link>
                          {/* <Button
                          variant="primary"
                          size="sm"
                          className="m-2"
                          onClick={() => handleAssignBlog(blog._id)}
                        >
                          Review
                        </Button> */}
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.authorDetails.email,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="discardqueue"
            title={
              <React.Fragment>
                Discard Queue
                <Badge variant="light" className="mx-1">
                  {discardQueueBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            {/* Discard Queue */}
            <h5 className="mt-4">
              <b>Discard Queue:</b>
            </h5>
            {discardQueueBlogs?.length === 0 ? (
              <div>No Discard Queue blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {discardQueueBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++l}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                          <p>
                            <i>author: {blog.authorDetails.email}</i> <br />
                            <i>Reviewer: {blog.currentReviewer}</i>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Form.Group
                            controlId={`assignUserSelect-${blog._id}`}
                          >
                            <Form.Control
                              as="select"
                              value={assignedUsers[blog._id] || ""}
                              onChange={(e) => handleAssignUser(e, blog._id)}
                            >
                              <option value="">Re-assign Reviewer</option>
                              {reviewers.map((reviewer) => (
                                <option
                                  key={reviewer._id}
                                  value={reviewer.email}
                                  disabled={
                                    blog.authorDetails.email === reviewer.email
                                  }
                                >
                                  {reviewer.email}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            size="sm"
                            className="m-2"
                            onClick={() => handleAssignBlog(blog._id)}
                          >
                            Reassign
                          </Button>
                        </div>

                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.authorDetails.email,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>

          <Tab
            eventKey="awaitingauthor"
            title={
              <React.Fragment>
                Awaiting Author
                <Badge variant="light" className="mx-1">
                  {awaitingAuthorBlogs?.length}
                </Badge>
              </React.Fragment>
            }
          >
            {/* Awaiting Author */}
            <h5 className="mt-4">
              <b>Awaiting Author:</b>
            </h5>
            {awaitingAuthorBlogs?.length === 0 ? (
              <div>No Awaiting Author blogs found</div>
            ) : (
              <>
                <ListGroup>
                  {awaitingAuthorBlogs?.map((blog) => (
                    <ListGroup.Item key={blog.slug}>
                      <div className="row align-items-center">
                        <div className="col">
                          <b>
                            {++m}. {blog.title}{" "}
                          </b>
                          <i
                            className="fas fa-eye"
                            onClick={() => handlePreviewBlogModal(blog._id)}
                          ></i>
                          <p>
                            <i>author: {blog.authorDetails.email}</i> <br />
                            <i>Reviewer: {blog.currentReviewer}</i>
                          </p>
                        </div>
                        <div className="col-auto">
                          <Form.Group
                            controlId={`assignUserSelect-${blog._id}`}
                          >
                            <Form.Control
                              as="select"
                              value={assignedUsers[blog._id] || ""}
                              onChange={(e) => handleAssignUser(e, blog._id)}
                            >
                              <option value="">Select Reviewer</option>
                              {reviewers.map((reviewer) => (
                                <option
                                  key={reviewer._id}
                                  value={reviewer.email}
                                  disabled={
                                    blog.authorDetails.email ===
                                      reviewer.email ||
                                    blog.currentReviewer === reviewer.email
                                  }
                                >
                                  {reviewer.email}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="primary"
                            size="sm"
                            className="m-2"
                            onClick={() => handleAssignBlog(blog._id)}
                          >
                            Update
                          </Button>
                        </div>
                        <div className="col-auto">
                          <Button
                            variant="danger"
                            size="sm"
                            className="m-2"
                            onClick={() =>
                              handleDiscardBlog(
                                blog._id,
                                blog.authorDetails.email,
                                blog.slug
                              )
                            }
                          >
                            Discard
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </Tab>
        </Tabs>

        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Preview Blog</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Title: {previewBlog?.title}
            <br />
            Slug: {previewBlog?.slug}
            <br />
            <br />
            Content: <br />
            <div dangerouslySetInnerHTML={{ __html: previewBlog?.content }} />
            <h6>Content size: {previewContentSize} KB</h6>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default BlogAssignment;
