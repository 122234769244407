import React from "react";
import { Container, Card, Button, Badge, Stack } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./AboutDeveloper.css";

function AboutDeveloper() {
const navigate= useNavigate();

  return (
    <div>
      <Helmet>
        <title>About Developer - BloggerSpace Reviewer Panel</title>
      </Helmet>
      <Container className="developer-page">
        <h2 className="developer-heading">About Developer</h2>
        <Card>
          <Card.Body>
            <div className="profile-section">
              <div className="profile-picture">
                <img
                  src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                  alt="Profile"
                />
                <h4>Teekam Singh</h4>
              </div>
              <div className="profile-details">
                <p>
                  Hello, I’m Teekam Singh currently working in Tata Consultancy
                  Services(TCS) having 1 year of Experience and completed my
                  B.Tech from ABES Engineering College, Ghaziabad.
                </p>
                <div className="skills">
                  <b>Skills: </b>
                  <Badge bg="secondary" className="skill-item">Flutter</Badge>
                  <Badge bg="secondary" className="skill-item">Firebase</Badge>
                  <Badge bg="secondary" className="skill-item">React.js</Badge>
                  <Badge bg="secondary" className="skill-item">Node.js</Badge>
                  <Badge bg="secondary" className="skill-item">MongoDB</Badge>
                  <Badge bg="secondary" className="skill-item">Java</Badge>
                  <Badge bg="secondary" className="skill-item">C++</Badge>
                </div>
                <br />
                <p>
                  <ul className="list-inline">
                    <strong>Connect on: </strong>
                    <li className="list-inline-item mx-2">
                      <a href="#">
                        <i className="fab fa-linkedin fa-lg"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mx-2">
                      <a href="#">
                        <i className="fab fa-github fa-lg"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mx-2">
                      <a href="#">
                        <i className="fab fa-facebook fa-lg"></i>
                      </a>
                    </li>
                    <li className="list-inline-item mx-2">
                      <a href="#">
                        <i className="fab fa-instagram fa-lg"></i>
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            {/* <h6>Check other projects developed previously:</h6>
            <div className="other-projects">
                <Card className="other-project-item">
                  <Card.Header>BrainQuiz</Card.Header>
                  <Card.Body>
                    This is brainquiz
                  </Card.Body>
                  <Card.Footer>
                    <Link type="button" to={"https://brainquiz.singhteekam.in/"} target="_blank">Demo</Link>
                  </Card.Footer>
                </Card>
                <Card className="other-project-item">
                  <Card.Header>MyDiary</Card.Header>
                  <Card.Body>
                    This is mydiary
                  </Card.Body>
                  <Card.Footer>
                  <Link type="button" to={"https://brainquiz.singhteekam.in/"} target="_blank">Demo</Link>
                  </Card.Footer>
                </Card>
                <Card className="other-project-item">
                  <Card.Header>SEI Official</Card.Header>
                  <Card.Body>
                    This is SEI website
                  </Card.Body>
                  <Card.Footer>
                  <Link type="button" to={"https://brainquiz.singhteekam.in/"} target="_blank">Demo</Link>
                  </Card.Footer>
                </Card>
            </div> */}

          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default AboutDeveloper;
